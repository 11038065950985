import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import {
  Section,
  NameBanner,
  BlogPostSnippet,
} from '@rumblefish/ui/Rumblefish23Theme';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

import type { HomePageRumbleFishBlogProps } from './HomePageRumbleFishBlog.types';
import {
  TextStack,
  BlogsStack,
  StyledStack,
  StyledTitle,
  ContentStack,
  MobileButton,
  DesktopButton,
} from './styles';

export const HomePageRumbleFishBlog = ({
  blogPosts,
}: HomePageRumbleFishBlogProps): ReactJSXElement => {
  const postsToDisplay = blogPosts.slice(0, 2);
  const router = useRouter();
  const handleOnClick = useCallback(
    (linkTo: string) => {
      if (!linkTo) return;
      router.push(linkTo);
    },
    [router]
  );

  return (
    <Section width="normal">
      <StyledStack>
        <NameBanner type="section" text={'Rumblefish Blog'} />

        <ContentStack>
          <TextStack>
            <StyledTitle variant="display_sm">
              Check a piece of expert knowledge
            </StyledTitle>

            <DesktopButton link="/blog" variant="primary">
              More updates
            </DesktopButton>
          </TextStack>

          <BlogsStack>
            {postsToDisplay.map(({ attributes }, index) => (
              <BlogPostSnippet
                onClick={() =>
                  handleOnClick(`/blog/post/${attributes?.slug || ''}`)
                }
                key={index}
                mainImageSrc={attributes?.image.data?.attributes?.url || ''}
                authors={attributes?.authors?.data || []}
                title={attributes?.title || ''}
                textContent={attributes?.content || ''}
                technologies={attributes?.category || []}
              />
            ))}
          </BlogsStack>

          <MobileButton link="/blog" variant="primary">
            More updates
          </MobileButton>
        </ContentStack>
      </StyledStack>
    </Section>
  );
};
