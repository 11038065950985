import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Stack, useTheme } from '@mui/material';
import {
  TestimonialsSlider,
  Button,
  NameBanner,
} from '@rumblefish/ui/Rumblefish23Theme';
import type { ISliderNavigationRef } from '@rumblefish/ui/src/components/Rumblefish23Theme/TestimonialsSlider/TestimonialsSlider.types';
import React, { useCallback, useRef } from 'react';
import {
  StyledButtonsStack,
  StyledStack,
  StyledTextStack,
  StyledTypography,
  NameBannerStack,
  StyledQuote,
  TestimonialsSection,
} from './styles';
import type { TestimonialsProps } from './Testimonials.types';

export const Testimonials = ({
  testimonialsData,
  ...props
}: TestimonialsProps): ReactJSXElement => {
  const theme = useTheme();
  const isMobile = theme.utils.isMobile();
  const sliderNavigationRef = useRef<ISliderNavigationRef>(null);
  const sectionRef = useRef<HTMLDivElement>(null);

  const handleMoveNext = useCallback(() => {
    sliderNavigationRef.current &&
      sliderNavigationRef.current.changeTestimonial('next');
  }, []);

  const handleMovePrev = useCallback(() => {
    sliderNavigationRef.current &&
      sliderNavigationRef.current.changeTestimonial('prev');
  }, []);
  return (
    <TestimonialsSection width="normal" borderBottomOn={!isMobile}>
      <StyledStack {...props} ref={sectionRef}>
        <StyledTextStack>
          <NameBannerStack>
            <NameBanner
              type="section"
              sx={{
                [theme.breakpoints.up(800)]: {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                },
              }}
              text="Testimonials"
            />
          </NameBannerStack>
          <StyledTypography variant="display_md">
            See what our customers say about working with us
          </StyledTypography>
          <StyledButtonsStack>
            <StyledQuote variant="display_lg">“</StyledQuote>
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: theme.spacing(1),
                marginRight: '0px',
                marginLeft: 'auto',
              }}>
              <Button
                variant="flat"
                iconDirection="left"
                aria-label="previous opinion"
                onClick={handleMovePrev}
              />
              <Button
                variant="flat"
                aria-label="next opinion"
                onClick={handleMoveNext}
              />
            </Stack>
          </StyledButtonsStack>
        </StyledTextStack>
        <TestimonialsSlider
          testimonialsData={testimonialsData}
          sectionRef={sectionRef}
          ref={sliderNavigationRef}
        />
      </StyledStack>
    </TestimonialsSection>
  );
};
