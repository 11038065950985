import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import type { NewCaseStudyEntity } from '@rumblefish/strapi/src/strapiTypes';
import {
  NameBanner,
  ZoomingImage,
  CaseStudyDetailsStack,
  NavigationButton,
} from '@rumblefish/ui/Rumblefish23Theme';
import type { LatestCaseStudyProps } from './LatestCaseStudy.types';
import {
  CenterStack,
  StyledContentStack,
  StyledDescription,
  StyledDescriptionStack,
  StyledImageSectionStack,
  StyledInfoStack,
  StyledStack,
  StyledTextStack,
  StyledTitle,
  LatestCaseStudySection,
  DesktopZoomingImage,
  MobileZoomingImage,
  GlowStack,
} from './styles';
export const LatestCaseStudy = ({
  latestCaseStudy,
  ...props
}: LatestCaseStudyProps): ReactJSXElement => {
  const isCaseStudyTypeofNewCaseStudy =
    latestCaseStudy?.__typename === 'NewCaseStudyEntity';

  const firstImgUrl =
    latestCaseStudy?.attributes?.homePageFirstImg?.data?.attributes?.url ??
    latestCaseStudy?.attributes?.image.data?.attributes?.url;
  const secondDesktopImgUrl =
    latestCaseStudy?.attributes?.homePageSecondImgDesktop?.data?.attributes
      ?.url ?? isCaseStudyTypeofNewCaseStudy
      ? (latestCaseStudy as NewCaseStudyEntity)?.attributes?.imagesCarousel
          ?.data[0]?.attributes?.url
      : null;
  const secondMobileImgUrl =
    latestCaseStudy?.attributes?.homePageSecondImgMobile?.data?.attributes
      ?.url ?? isCaseStudyTypeofNewCaseStudy
      ? (latestCaseStudy as NewCaseStudyEntity)?.attributes?.imagesCarousel
          ?.data[0]?.attributes?.url
      : null;

  return (
    <LatestCaseStudySection width="normal">
      <GlowStack />
      <StyledStack {...props}>
        <NameBanner type="section" text={'Latest case study'} />

        <StyledTitle display={{ md: 'none' }} variant="display_sm">
          {latestCaseStudy.attributes?.title}
        </StyledTitle>

        <StyledContentStack>
          <StyledTextStack>
            <StyledTitle
              display={{ xs: 'none', md: 'block' }}
              variant="display_sm">
              {latestCaseStudy.attributes?.title}
            </StyledTitle>

            <StyledDescriptionStack>
              <StyledDescription variant="strong_500">
                {latestCaseStudy.attributes?.cardDescription}
              </StyledDescription>
              <NavigationButton
                target={`/case-studies/${
                  latestCaseStudy.attributes?.slug || ''
                }`}
                buttonText="View this case study"
                color={'raspberry'}
                ariaLabel={latestCaseStudy.attributes?.title?.toString()}
              />
            </StyledDescriptionStack>
          </StyledTextStack>
          <StyledImageSectionStack>
            <CenterStack>
              {firstImgUrl && (
                <ZoomingImage imgUrl={firstImgUrl} fromRightSideOn />
              )}
              <StyledInfoStack>
                <CaseStudyDetailsStack
                  collaborationTimeframe={
                    latestCaseStudy.attributes?.collaborationTimeframe
                  }
                  services={latestCaseStudy.attributes?.services}
                />
              </StyledInfoStack>
            </CenterStack>
          </StyledImageSectionStack>
        </StyledContentStack>

        {secondDesktopImgUrl && (
          <DesktopZoomingImage imgUrl={secondDesktopImgUrl} />
        )}

        {secondMobileImgUrl && (
          <MobileZoomingImage imgUrl={secondMobileImgUrl} />
        )}
      </StyledStack>
    </LatestCaseStudySection>
  );
};
