import { Stack, styled, Typography } from '@mui/material';
import { Section, ZoomingImage } from '@rumblefish/ui/Rumblefish23Theme';
export const LatestCaseStudySection = styled(Section)(() => ({
  contain: 'paint',
  overflow: 'hidden',
}));
export const StyledStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(16, 0),
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(8),
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(9, 0),
  },
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(4),
  },
}));
export const StyledContentStack = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingBottom: theme.spacing(6),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse',
  },
}));

export const StyledTextStack = styled(Stack)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(8),
  alignItems: 'flex-end',
  [theme.breakpoints.down('lg')]: {
    gap: theme.spacing(5),
  },
}));
export const StyledImageSectionStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },

  flex: 1,
}));
export const CenterStack = styled(Stack)(({ theme }) => ({
  margin: '0 auto',
  width: '60%',
  height: '100%',
  gap: theme.spacing(5),
  [theme.breakpoints.down('md')]: {
    width: '100%',
    margin: theme.spacing(4, 0),
  },
}));
export const StyledTitle = styled(Typography)(({ theme }) => ({
  ...theme.mixins.neutralGradient,
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  clipPath: 'inset(1px)',
}));

export const StyledDescriptionStack = styled(Stack)(({ theme }) => ({
  width: '70%',
  gap: theme.spacing(4),
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}));
export const StyledDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.PRIMARY,
}));
export const StyledInfoStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
}));
export const GlowStack = styled(Stack)(() => ({
  width: '70%',
  height: 'calc(70vw / 2)',
  borderRadius: '0 0 calc(70vw / 2) calc(70vw / 2)',
  position: 'absolute',
  transform: 'translateX(-50%) translateY(0%) translateZ(0)',
  zIndex: -1,
  left: '50%',
  background: 'rgba(255,255,255,0.05)',
  willChange: 'transform',
  filter: 'blur(200px)',
}));
export const MobileZoomingImage = styled(ZoomingImage)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));
export const DesktopZoomingImage = styled(ZoomingImage)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));
