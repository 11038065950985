import { useTheme } from '@mui/material';
import {
  AwsIcon,
  WebIcon,
  MobileIcon,
  DefiIcon,
  FintechIcon,
  BlockchainIcon,
  TeamIcon,
  DesignIcon,
  StartupIcon,
  AIIcon,
} from '@rumblefish/ui/Rumblefish23Theme';
import type { IAccordionData } from '@rumblefish/ui/src/components/Rumblefish23Theme/Accordion/CustomizedAccordion.types';

export const whatWeDoData = {
  mainText: 'Software Development Services and Skills for your needs',
  description: `  To deliver the highest quality of services, our experts are always
  gaining new skills and knowledge. That’s how we make sure our
  solutions follow the latest industry standards and take advantage
  of the most innovative technologies.`,
};

export const AccordionData = (): IAccordionData[] => {
  const theme = useTheme();
  return [
    {
      title: 'AWS Cloud Solutions',
      description:
        'Our experienced team will take your AWS cloud solutions to the next level. AWS provides purpose-built tools to support your needs, and it is the preferred choice for any blockchain project. From the plethora of cloud tools and solutions offered by Amazon Web Services, we’ll help you choose and implement the ones that serve your business the best way possible.',
      linkTo: '/services/aws-cloud-solutions/',
      icon: AwsIcon,
      gradientOnFocus: theme.mixins.redGradient?.background as string,
    },

    {
      title: 'Web Development',
      description:
        "If you’re in need of professional web development services, look no further! Rumble Fish's talented team has extensive experience in delivering top-tier web apps and websites with the use of battle-tested tools and technologies like React or Nest. We know just the right solutions to exceed your business requirements.",
      linkTo: '/services/web-development/',
      icon: WebIcon,
      gradientOnFocus: theme.mixins.raspberryGradient?.background as string,
    },
    {
      title: 'Mobile App Development',
      description:
        'Whether you need an Android, an IOS app, or both, the Rumble Fish team is here to help you deliver the beautiful and efficient mobile product that your customers will simply love to use! We craft fast and secure mobile apps with a wow factor to help our customers grow their businesses and reach their goals quicker.',
      linkTo: '/services/mobile-development/',
      icon: MobileIcon,
      gradientOnFocus: theme.mixins.magentaGradient?.background as string,
    },
    {
      title: 'AI Chat Assistant Development',
      description: `AI chatbots can bring value to a wide range of industries by enhancing customer interactions, streamlining processes, and improving overall efficiency. We'll craft a perfect AI assistant for your product.`,
      linkTo: '/services/ai-chat-assistant/',
      icon: AIIcon,
      gradientOnFocus: theme.mixins.purpleGradient?.background as string,
    },
    {
      title: 'DeFi Development',
      description:
        'Decentralized Finance (DeFi) development requires an extensive amount of blockchain knowledge, as well as a great understanding of financial mechanisms. We’ve got both that bases covered! Our team has successfully built an impressive number of DeFi products like cryptocurrency exchanges, dApps, lending protocols, or staking platforms. Try us!',
      linkTo: '/services/defi-development/',
      icon: DefiIcon,
      gradientOnFocus: theme.mixins.grapeGradient?.background as string,
    },
    {
      title: 'Fintech Software Development',
      description:
        'Looking for a skilled team to help you build an advanced fintech platform able to compete with the biggest in the game? At Rumble Fish, we’ve got what it takes to engineer innovative financial technology systems. We offer end-to-end fintech software development, consulting, and expertise.',
      linkTo: '/services/fintech-software-development/',
      icon: FintechIcon,
      gradientOnFocus: theme.mixins.blueGradient?.background as string,
    },
    {
      title: 'Blockchain Software Development',
      description:
        'Our team is well-versed and experienced in various blockchain development tools and technologies. Our unique skillset allows us to be at the forefront of Web3 development services so if you’re looking for a trusted IT partner to boost your decentralized product - look no further!',
      linkTo: '/services/blockchain-software-development/',
      icon: BlockchainIcon,
      gradientOnFocus: theme.mixins.cyanGradient?.background as string,
    },
    {
      title: 'Dedicated Development Teams',
      description:
        'Our experts provide you with knowledge, skills, and experience that elevates every project to another level. We’ll gladly take ownership of the entire process and guide you and your team through the intricacies of cutting-edge technology development.',
      linkTo: '/services/dedicated-development-teams/',
      icon: TeamIcon,
      gradientOnFocus: theme.mixins.tealGradient?.background as string,
    },
    {
      title: 'UI/UX',
      description:
        'We design sleek, intuitive, and highly effective interfaces to help you overcome your business challenges. After carefully evaluating and understanding your requirements we switch to the designing mode - the end goal is the beautiful digital solution that people love to use!',
      linkTo: '/services/product-design',
      icon: DesignIcon,
      gradientOnFocus: theme.mixins.aquamarineGradient?.background as string,
    },
    {
      title: 'IT services for your startup',
      description: `If you're looking for a team capable of turning your product concept into a beautiful and technologically intricate digital solution - look no further! Rumble Fish is your trusted software development partner ready to take you through the entire process of custom digital product creation - from the early stages of ideation to the post-launch support. Whether you're on a mission to build a mobile app, a Web3 product, or an advanced platform - we are here for you!`,
      linkTo: '/startup',
      icon: StartupIcon,
      gradientOnFocus: theme.mixins.greenGradient?.background as string,
    },
  ];
};
